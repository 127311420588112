<template>
  <div class="reg">
    <div class="reg-header">
      <topNav></topNav>
    </div>
    <div class="reg-main">
      <div class="m-steps">
        <Steps :current="curIndex">
          <Step title="验证手机"></Step>
          <Step title="基本信息"></Step>
          <Step title="完成注册"></Step>
        </Steps>
      </div>

      <div class="F">
        <img src="@/assets/img/icon-success.png" />
        <h3>注册成功</h3>
        <p v-if="!exhibitionConfig.visitorReg.regSuccessedNotice">尊敬的{{ user.name || user.nickName }}，您已成功登记为”{{ exhibitionInfo.name }}“的观众</p>
        <p v-else>
          {{ exhibitionConfig.visitorReg.regSuccessedNotice.replace('【条码】', code) }}
        </p>
        <div style="padding-top: 30px; text-align:center;">
          <!-- <Button
            type="primary"
            @click="$router.push(`/user/card?exhibitionId=${exhibitionId}`)"
            size="large"
            style="width: 180px;margin-right: 20px"
            class="btn"
            >查看我的证件</Button
          > -->
          <Button type="primary" ghost @click="$router.push(`/home/index/${exhibitionId}`)" size="large" class="btn" style="width: 180px;">返回首页</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { validator } from "@/other/validator";
import topNav from "./components/top";

export default {
  name: "reg",
  data() {
    return {
      curIndex: 2,
    };
  },
  components: {
    topNav,
  },
  computed: {
    ...mapGetters({
      token: "getToken",
      company: "loginModule/getCompany",
      exhibitionInfo: "getExhibitionInfo",
      user: "getUser",
      exhibitionConfig: "getExhibitionConfig",
    }),
    exhibitionId() {
      return this.$route.params.id;
    },
    code() {
      return this.$route.query.code;
    },
  },
  created() {
    if (this.user && this.user.userId && this.token) {
      this.getUser();
    }
    this.getUser();
  },
  methods: {
    ...mapMutations({
      setUser: "setUser",
      setUser: "setUser",
      setUserId: "setUserId",
      setLogin: "setLogin",
    }),
    ...mapActions({
      graphqlPost: "graphqlPost",
      linkToLogin: "linkTo/linkToLogin",
    }),
    async getUser() {
      let query = `
        query{
          memberQuery{
            current{
              address
              avatarUrl
              balance
              birthday
              businessCard
              city
              company
              country
              county
              createAt
              deleteAt
              department
              email
              followCount
              id
              inMember(exhibitionId:"${this.exhibitionId}"){ 
                business
                category
                id 
                memberId
                originId
                originLang
                originRemark
                isBlacklist
                originType
                regAt
                sayCount
                sayUserCount
                userId
                userRole
                exhibitorId
                companyUsers{
                  id
                  isAppointment
                }
              }
              introduction
              isDeleted
              jobTitle
              name
              nickName
              phone
              phoneConfirm
              province
              talkCount
              talkSuccessedCount
              title
              updateAt
              userId
            }
          } 
        }
      `;
      let opt = {
        opt: {
          query: query,
        },
        token: this.token,
      };
      let data = await this.graphqlPost(opt);
      if (data.data && data.data.memberQuery && data.data.memberQuery.current) {
        this.setLogin(true);
        this.setUser(data.data.memberQuery.current);
        this.setUserId(data.data.memberQuery.current.userId);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.reg {
  &-header {
    box-shadow: 0px 2px 3px 0px rgba(101, 101, 101, 0.1);
  }
  &-main {
    width: 1200px;
    margin: 50px auto;
    .m-steps {
      width: 710px;
      margin: 0 auto;
      ::v-deep .ivu-steps-item {
        .ivu-steps-head {
          background: transparent;
        }
      }
    }
  }
  .F {
    width: 600px;
    margin: 34px auto;
    img {
      margin: 0 auto;
      display: block;
    }
    h3 {
      font-size: 18px;
      text-align: center;
      margin-top: 20px;
    }
    p {
      text-align: center;
      margin-top: 10px;
    }
  }
}
</style>
