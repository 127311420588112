<template>
  <div class="top-user">
    <Row>
      <Col span="10" class="top">
        <div class="top-l">
          <div class="top-l-l">
            <a href="javascript:;" @click="$router.push(`/home/index/${exhibitionId}`)">
              <img :src="exhibitionInfo.logo" v-if="exhibitionInfo && exhibitionInfo.logo" style="width:100px;height:100px;" />
              <img src="@/assets/img/icon-zsx2.png" v-else style="padding: 35px 10px;" />
            </a>
            <span>欢迎注册</span>
          </div>
        </div>
      </Col>
      <Col span="14" v-if="!login">
        <div class="top-r">已有账号？<span @click="linkToLogin">请登录</span></div>
      </Col>
    </Row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
      login: "getLogin",
    }),
  },
  methods: {
    ...mapActions({
      linkToLogin: "linkTo/linkToLogin",
    }),
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.top-user {
  width: 1200px;
  margin: 0 auto;
  .top {
    &-msg {
      position: relative;
      padding: 0 16px 0 10px;
      border-left: 1px solid #e6e6e6;
      font-size: 12px;
      color: #333;
      h2 {
        font-size: 14px;
        color: #1a1a1a;
        display: flex;
        align-items: center;
        font-style: normal;
        margin-bottom: 6px;
        span {
          @include font_color(#0190fe);
          display: inline-block;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
    &-l {
      height: 100px;
      &-l {
        display: flex;
        align-items: center;
        img {
          vertical-align: middle;
          margin-right: 20px;
        }
        span {
          font-size: 18px;
          color: #333;
        }
      }
    }
    &-r {
      height: 100px;
      text-align: right;
      align-items: center;
      justify-content: flex-end;
      display: flex;
      span {
        cursor: pointer;
        color: #ff5e00;
      }
    }
  }
}
</style>
